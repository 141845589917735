import React, { useEffect, useState } from 'react';

// Bootstrap elements
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import axios from "axios";

import './App.css'


const categoriesEndpoint = process.env.REACT_APP_TARGET + "/kategories.json"
const createMessageEndpoint = process.env.REACT_APP_TARGET + "/smessages.json"
//const categoriesEndpoint = "http://172.17.0.2:3000/kategories.json"
//const createMessageEndpoint = "http://172.17.0.2:3000/smessages.json"

const App: React.FC = () => {

  const [code, setCode] = useState("");
  const [category, setCategory] = useState('');
  const [message, setMessage] = useState('');

  // select options
  const [options, setOptions] = useState([] as any);

  useEffect(() => {
    async function fetchData() {
      // data, returned from rest-api call
      const { data } = await axios.get(categoriesEndpoint);
      const results: { key: any; value: any; }[] = []
      // Store results in the results array

      console.log(data);

      data.forEach((val) => {
        if (val.id !== -1) { // -1 -> neu angekommen, nicht zuegordnet
          // ignored, because we do want only real categories
          if (val.name !== "nicht zusortiert") {
            results.push({
              key: val.name,
              value: val.id,
            });
          }
        }
      });
      // Update the options state
      setOptions([
        { key: 'Kategorie wählen...', value: '' }, ...results
      ])
    }

    // Trigger the fetch
    fetchData();
  }, [])  // the square brackets make the useEffect called just after the first render
  // otherwise the app will reload the ajax data after each change

  // handles the submit of the form
  // must not sent to the server directly with reload
  // just aajax call in the background + promise for success and for errors
  function handleSubmit(e) {
    e.preventDefault();
    let sprache = process.env.REACT_APP_LANG
    console.log("handleSubmit: ", code, category, sprache, message);
    const postdata = { code: code, wunsch_id: category, content: message, sprache: sprache }
    axios.post(createMessageEndpoint, postdata)
      .then(results => {
        alert(langsel(process.env.REACT_APP_LANG, 6));
        // clear data area
        resetForm()
        //setCategory(null)
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
        if (error.status === 422) {
          alert(langsel(process.env.REACT_APP_LANG, 7));
        }
        else {
          alert(langsel(process.env.REACT_APP_LANG, 8));
        }
      });
  }

  // processes changes of the select box and transfers the changed data
  // into the state variable category
  function handleSelectChange(e) {
    setCategory(e.target.value)
  }

  // processes changes of the code input box and transfers the changed data
  // into the state variable code
  function handleCodeChange(e) {
    setCode(e.target.value)
  }



  function resetForm() {
    setMessage("")
    setCategory("")
  }

  function langsel(lang, selektor) {
    if (lang === "de") {
      switch (selektor) {
        case 1:
          return "Code";
        case 2:
          return "Ihre Frage / Ihre Nachricht";
        case 3:
          return "Geben Sie Ihre Nachricht ein..."
        case 4:
          return "Zurücksetzen";
        case 5:
          return "Absenden";
        case 6:
          return "Vielen Dank, Ihre Nachricht wurde übermittelt!";
        case 7:
          return "Code falsch oder Nachricht schon einmal gesendet";
        case 8:
          return "Fehler aufgetreten: Code falsch oder Nachricht schon einmal gesendet";
      }
    }
    else if (lang === "fr") {
      switch (selektor) {
        case 1:
          return "Code";
        case 2:
          return "Votre question / Votre message";
        case 3:
          return "Saisissez votre message..."
        case 4:
          return "Réinitialiser";
        case 5:
          return "Envoyer";
        case 6:
          return "Merci beaucoup, votre message a été transmis!";
        case 7:
          return "Code incorrect ou message déjà envoyé";
        case 8:
          return "Une erreur s'est produite : Code incorrect ou message déjà envoyé";
      }

    }
    else if (lang === "it") {
      switch (selektor) {
        case 1:
          return "Codice";
        case 2:
          return "La vostra domanda / Il vostro messaggio";
        case 3:
          return "Inserisci il tuo messaggio..."
        case 4:
          return "Reset";
        case 5:
          return "Invia";
        case 6:
          return "Grazie, il vostro messaggio è stato inviato!";
        case 7:
          return "Il codice non è corretto o il messaggio è già stato inviato";
        case 8:
          return "Si è verificato un errore: Codice errato o messaggio già inviato";
      }

    }
    else if (lang === "en") {
      switch (selektor) {
        case 1:
          return "Code";
        case 2:
          return "Your question / Your message";
        case 3:
          return "Please enter your message..."
        case 4:
          return "Reset";
        case 5:
          return "Submit";
        case 6:
          return "Thank you, your message has been sent!";
        case 7:
          return "Code incorrect or message has already been sent";
        case 8:
          return "An error has occurred: Code incorrect or message already sent";
      }

    }

  }

  return (
    <div className="App-header">
      <Container className="p-3">
        <div className="logoArea">
          <img src="KNDS-white.svg" alt="KMW LOGO" width="250" />
        </div>

        <Form id="messageForm" onSubmit={handleSubmit} >
          <Form.Group className="mb-3" controlId="veranstaltungsCode">
            <Form.Label>
              {langsel(process.env.REACT_APP_LANG, 1)}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Code..."
              value={code}
              onChange={handleCodeChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="kategorie">
            <Form.Label>{
            }</Form.Label>
            <Form.Select
              value={category}
              name="category"
              onChange={handleSelectChange}>
              {options.map((opt) => (
                <option value={opt.value}>{opt.key}</option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="message">
            <Form.Label>
              {langsel(process.env.REACT_APP_LANG, 2)}
            </Form.Label>
            <Form.Control
              id="message"
              as="textarea"
              placeholder={langsel(process.env.REACT_APP_LANG, 3)}
              value={message}
              maxLength={256}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Form.Group>

          <Button variant="success" type="submit" >
            {langsel(process.env.REACT_APP_LANG, 5)}
          </Button>
          &nbsp;
          <Button variant="secondary" onClick={resetForm} >
            {langsel(process.env.REACT_APP_LANG, 4)}
          </Button>

        </Form>
      </Container >
    </div>
  );
};

export default App;